import { ChakraTheme, extendTheme } from "@chakra-ui/react";
import { DeepPartial } from "utility-types";

const theme: DeepPartial<ChakraTheme> = {
  fonts: {
    heading: "Open Sans, sans-serif",
    body: "Open Sans, sans-serif",
  },
  colors: {
    brand: {
      primary: "#01bb75",
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 700,
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          color: "brand.primary",
          textDecoration: 'none'
        },
      },
    },
  },
};

export default extendTheme(theme);
